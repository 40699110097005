<template>
    <div>
        <el-card>
            <template v-if="!user">
                <div slot="header">
                    请登陆用户账号
                    <i class="el-icon-close" style="float: right;cursor: pointer"
                       @click="$emit('close-user',account)"/>
                </div>
                <el-form>
                    <el-form-item label="手机号码">
                        <el-input v-model="loginForm.mobile"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号码">
                        <el-input v-model="loginForm.password"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="login">登录</el-button>
                    </el-form-item>
                </el-form>
            </template>
            <template v-else>
                <div slot="header" class="clearfix">
                    <div style="float: left">
                        用户：{{user.nickname}}[{{user.id}}]
                        <span v-if="isJoined">
                            &nbsp;&nbsp;&nbsp;所在直播间：{{roomNo}}
                       </span>
                    </div>

                    <div style="float: right" v-if="isJoined">
                        <el-button size="small" type="warning" @click="sendMessage" style="margin-right: 20px">发消息
                        </el-button>
                        <el-button size="small" type="warning" @click="follow" style="margin-right: 20px">关注主播
                        </el-button>
                        <el-button size="small" type="danger" @click="joinClub" style="margin-right: 20px">加入粉丝团
                        </el-button>
                        <el-button size="small" type="success" @click="buyGuard" style="margin-right: 20px">开通守护
                        </el-button>
                        <template v-if="gifts.length > 0">
                            <el-popover
                                    ref="giftList"
                                    placement="left"
                                    width="600"
                                    trigger="click">
                                <el-tabs value="first">
                                    <el-tab-pane v-for="(group,index) in gifts" :key="group.category.id"
                                                 :label="group.category.name"
                                                 :name="index === 0 ? 'first' : group.category.id">
                                        <div class="gift_list">
                                            <div class="gift_item" :key="gift.id" v-for="(gift) in group.items">
                                                <img :src="gift.image" style="width: 60px;height: 60px"/>
                                                <p class="name">{{gift.name}}</p>
                                                <p class="price">{{gift.price}}</p>
                                                <el-button size="small" @click="presentGift(gift.id)">赠送</el-button>
                                            </div>
                                        </div>
                                    </el-tab-pane>
                                </el-tabs>
                            </el-popover>
                            <el-button v-popover:giftList type="primary" size="small">送礼物</el-button>
                            <span style="margin-left: 10px;color: chocolate">余额：{{user.gold}}</span>
                            <i class="el-icon-close" style="margin-left:50px;cursor: pointer"
                               @click="$emit('close-user',account)"/>
                        </template>
                    </div>
                    <i v-else class="el-icon-close" style="float:right;margin-left:50px;cursor: pointer"
                       @click="$emit('close-user',account)"/>

                </div>
                <div>
                    <el-row>
                        <el-col :span="24">
                            <el-card>
                                <div slot="header" class="clearfix">

                                    <template v-if="connected">
                                        <el-tag type="success" style="margin-right: 20px">已连接</el-tag>

                                        <el-tag v-if="isJoined" type="success">直播间{{roomNo}}</el-tag>

                                        <el-button size="small" v-if="!isJoined && room" type="danger"
                                                   @click="joinRoom">点击加入房间
                                        </el-button>
                                    </template>
                                    <template v-else>
                                        <el-tag type="danger">未连接</el-tag>

                                    </template>

                                    <el-button style="float: right; padding: 3px 0" type="text" @click="messages = []">
                                        清空({{messages.length}})
                                    </el-button>
                                </div>
                                <div style="height: 500px; overflow-y: scroll">
                                    <el-card v-for="(item,index) in messages" :key="index" style="margin-bottom: 20px">
                                        <div slot="header" class="clearfix">
                                            <span>
                                                {{item.event}}
                                            </span>
                                            <span style="float: right;color: #cccccc">
                                                {{item.time}}
                                            </span>
                                        </div>

                                        <vue-json-pretty :deep="0" :data="item.data"></vue-json-pretty>

                                    </el-card>
                                </div>
                            </el-card>
                        </el-col>
                    </el-row>
                </div>
            </template>
        </el-card>
    </div>
</template>

<script>
    import mixin from "@/mixin";
    import 'vue-json-pretty/lib/styles.css'
    import VueJsonPretty from "vue-json-pretty";

    export default {
        name: "anchor",
        mixins: [mixin],
        props: {
            account: {
                type: Object
            }
        },
        components: {
            VueJsonPretty,
        },
        data() {
            return {
                gifts: []

            }
        },
        created() {
            this.loginForm.mobile = this.account.mobile;
            this.loginForm.password = this.account.password;
            this.roomNo = this.account.targetRoomNo
        },
        mounted() {
            // this.login()
        },
        methods: {
            getRoom() {
                this.getGifts()
                this.initSocket()
            },
            presentGift(id) {
                this.socket.io.emit('presentGift', {room_no: this.roomNo, gift_id: id, num: 1})
            },
            init() {
                this.sockets.subscribe('pkRequest', (data) => {
                    this.pkRequest = data;
                })
                this.sockets.subscribe('pkRequestTimeout', (data) => {
                    this.pkRequest = null;
                })
                this.sockets.subscribe('updateUser', (data) => {
                    for (let key in data) {
                        this.user[key] = data[key];
                    }
                })
                this.sockets.subscribe('locked', (data) => {
                    this.socket.io.disconnect()
                    this.socket.io.close()
                    this.$alert(data.content)
                })
            },
            getGifts() {
                this.request.get('audience/gifts').then(res => {
                    this.gifts = res.data
                });
            },
            sendMessage() {
                this.$prompt('输入内容', '操作', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    closeOnClickModal: false,
                }).then(({value}) => {
                    this.socket.io.emit('sendMessage', {content: value, room_no: this.roomNo});
                }).catch(() => {

                });
            },
            buyGuard() {
                this.request.post('audience/buy-guard', {room_no: this.roomNo, 'long': 'month'})
            },
            follow() {
                this.request.post('user/follow', {id: this.roomNo})
            },
            joinClub() {
                this.request.post('club/join', {id: this.roomNo});
            }
        }
    }
</script>

<style scoped>
    .clearfix:before,
    .clearfix:after {
        display: table;
        content: "";
    }

    .clearfix:after {
        clear: both
    }

    .gift_list {
        display: flex;
        justify-content: space-start;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .gift_item {
        width: 100px;
        height: 160px;
        text-align: center;
        margin: 10px;
    }

    .gift_item img {
        width: 60px;
        height: 60px;
        border: 1px solid #efefef
    }

    .gift_item .name {
        font-size: 12px;
        color: #454545;
        padding: 0;
        line-height: 14px
    }

    .gift_item .price {
        font-size: 12px;
        color: burlywood;
        line-height: 14px
    }
</style>
