import VueSocketIO from 'vue-socket.io'
import axios from "axios";
import dayjs from 'dayjs'

export default {
    data() {
        return {
            request: null,
            socket: null,
            token: null,
            user: null,
            messages: [],
            loginForm: {
                mobile: "",
                password: ""
            },
            room: null,
            connected: false,
            roomNo: null,
            isJoined: false,
            eventList: ['connect', 'disconnect', 'error', 'receiveAnnouncement',
                'welcome', 'updateRoom', 'receiveGift', 'updateUser', 'receiveGlobalGift', 'receiveMessage',
                'pkRequest', 'pkReject', 'pkAgree', 'pkStart', 'pkUpdateProgress', 'pkEnd', 'pkClose'],
        }
    },
    created() {
        this.initRequest()

        // this.eventList.forEach((event) => {
        //     this.sockets.subscribe(event, data => this.addMessage(event, data))
        // })

    },
    beforeDestroy() {
        if (this.socket) {
            Object.keys(this.$options.sockets).forEach(event => {
                this.socket.emitter.removeListener(event, this);
            });
            this.socket.io.disconnect();
            this.socket.io.close();
        }
    },
    computed: {
        getToken() {
            return this.token;
        }
    },
    sockets: {
        connect() {
            this.connected = true
            this.joinRoom()
        },
        receiveAnnouncement() {
            this.isJoined = true
        },
        disconnect() {
            this.isJoined = false
            this.connected = false
        },
        error(data) {
            this.$message({
                message: data.error.message,
                type: 'error',
                duration: 5 * 1000
            })
        }
    },


    methods: {
        joinRoom() {
            console.log("this.roomNo", this.roomNo)
            if (this.roomNo) {
                this.socket.io.emit('joinRoom', {room_no: this.roomNo})
            }
        },
        initRequest() {
            this.request = axios.create({
                baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
                // withCredentials: true, // send cookies when cross-domain requests
                timeout: 20000 // request timeout
            });
            this.request.interceptors.request.use(
                config => {
                    config.headers['Content-Type'] = 'application/json'
                    config.headers['Accept'] = 'application/json,text/html, */*'
                    config.headers['Authorization'] = 'Bearer ' + this.getToken
                    config.url = config.url.replace(config.baseURL + '/', config.baseURL)
                    return config
                },
                error => {
                    return Promise.reject(error)
                }
            );
            this.request.interceptors.response.use(
                response => {
                    const res = response.data
                    if (res.hasOwnProperty('error')) {
                        if (res.error.code === 401) {
                            this.user = null
                            this.token = null
                        } else {
                            this.$message({
                                message: res.error.message,
                                type: 'error',
                                duration: 5 * 1000
                            })
                            // return Promise.reject(new Error(res.error.message || 'Error'))
                        }
                    } else {
                        return res
                    }
                },
                error => {
                    this.$message({
                        message: error.message,
                        type: 'error',
                        duration: 5 * 1000
                    })
                    return Promise.reject(error)
                }
            )
        },
        initSocket() {
            this.socket = new VueSocketIO({
                debug: true,
                connection: process.env.VUE_APP_SOCKET_URL,
                options: {
                    transports: ['websocket'],
                    query: {
                        token: this.getToken
                    },
                    autoConnect: true,
                    path: '/'

                } //Optional options
            })
            if (!this.sockets) this.sockets = {};

            if (this.$options.sockets) {
                Object.keys(this.$options.sockets).forEach(event => {
                    if (event !== 'subscribe' && event !== 'unsubscribe') {
                        this.socket.emitter.addListener(event, this.$options.sockets[event], this);
                    }
                });
            }

            this.sockets.subscribe = (event, callback) => {
                this.socket.emitter.addListener(event, callback, this);
            };

            this.sockets.unsubscribe = (event) => {
                this.socket.emitter.removeListener(event, this);
            };
            this.socket.io.onevent = (packet) => {
                let [event, ...args] = packet.data;

                if (args.length === 1) args = args[0];
                // if (event.substring(0, 11) === 'interaction') {
                //     this.addMessage(event, args);
                // }
                this.addMessage(event, args);
                this.socket.emitter.emit(event, args);
            };

            this.init();
        },

        init() {

        },
        getUserInfo() {
            this.request.get('profile').then((res) => {
                this.user = res.data;
                this.getRoom();

            })
        },
        getRoom() {

        },
        login() {
            this.request.post("auth/login", {
                mobile: this.loginForm.mobile,
                password: this.loginForm.password,
                type: 2
            }).then((res) => {
                this.token = res.data.access_token;
                this.getUserInfo();
            }).catch(e => {
                this.$message.error(e.message);
            });
        },
        logout() {
            this.token = null
            this.user = null
        },
        addMessage(event, data) {
            this.messages = [{
                time: dayjs().format('HH:mm:ss'),
                event: event,
                data: data,
            }, ...this.messages]
        },
    }
}
