<template>
    <div>
        <el-row :gutter="20" style="position: relative">
            <el-col :span="24 / (anchors.length)" v-for="(anchor,index) in anchors" :key="index">
                <el-card style="margin-bottom: 10px" :body-style="{ padding: '10px' }">
                    <div class="add-div">
                        <div class="add" @click="addAnchor" v-if="anchors.length < 4">
                            <i class="el-icon el-icon-plus"/>
                            <span>添加主播({{anchors.length}})</span>
                        </div>
                        <div class="add" @click="addUser(anchor)">
                            <i class="el-icon el-icon-plus"/>
                            <span>添加观众({{anchor.users.length}})</span>
                        </div>
                    </div>
                </el-card>
                <anchor :key="anchor.mobile" :account="anchor" @close-anchor="closeAnchor"/>
                <user style="margin-top: 20px" v-for="user in anchor.users" :key="user.targetRoomNo +'_' +user.mobile"
                      :account="user"
                      @close-user="closeUser"></user>
            </el-col>
            <el-col :span="24" v-if="anchors.length  === 0">
                <el-card>
                    <div class="add" @click="addAnchor">
                        <i class="el-icon el-icon-plus"/>
                        <span>添加主播</span>
                    </div>
                </el-card>
            </el-col>

        </el-row>

    </div>
</template>

<script>
    import anchor from "@/components/anchor";
    import user from "@/components/user";

    export default {
        components: {
            anchor, user
        },
        data() {
            return {
                anchors: [],
                users: [],
                anchorAccounts: [
                    // {
                    //     mobile: "15357940356",
                    //     password: "123456",
                    //     roomNo: "10000002",
                    //     users: [],
                    // },
                    {
                        mobile: "17828350479",
                        password: "123456",
                        roomNo: "10000050",
                        users: [],
                    },
                    {
                        mobile: "13374689798",
                        password: "123456",
                        roomNo: "10000049",
                        users: [],
                    },
                    {
                        mobile: "15845906751",
                        password: "123456",
                        roomNo: "10000048",
                        users: [],
                    },
                    {
                        mobile: "18726046645",
                        password: "123456",
                        roomNo: "10000044",
                        users: [],
                    },
                    {
                        mobile: "18687407687",
                        password: "123456",
                        roomNo: "10000042",
                        users: [],
                    },
                    {
                        mobile: "18698176040",
                        password: "123456",
                        roomNo: "10000041",
                        users: [],
                    },
                    {
                        mobile: "17733024436",
                        password: "123456",
                        roomNo: "10000040",
                        users: [],
                    }
                ],
                userAccount: [
                    {
                        mobile: "15767228926",
                        password: "123456",
                    },
                    {
                        mobile: "13952831971",
                        password: "123456",
                    },
                    {
                        mobile: "17087965132",
                        password: "123456",
                    },
                    {
                        mobile: "13784992144",
                        password: "123456",
                    },
                    {
                        mobile: "18858626396",
                        password: "123456",
                    },
                    {
                        mobile: "15533341595",
                        password: "123456",
                    },
                    {
                        mobile: "17773204254",
                        password: "123456",
                    },
                    {
                        mobile: "18956810810",
                        password: "123456",
                    },
                    {
                        mobile: "13602647459",
                        password: "123456",
                    },
                ]
            }
        },
        methods: {
            addAnchor() {
                this.anchorAccounts.some((item) => {
                    if (this.anchors.every((anchor) => {
                        return anchor.mobile !== item.mobile
                    })) {
                        this.anchors.push(Object.assign({},item));
                        return true;
                    }
                })
            },
            closeAnchor(account) {
                this.anchors.some((item, index) => {
                    if (item.mobile === account.mobile) {
                        this.anchors.splice(index, 1)
                        return true
                    }
                });
            },
            addUser(anchor) {
                console.log(anchor)
                this.userAccount.some((item) => {
                    if (anchor.users.every((user) => {
                        return user.mobile !== item.mobile
                    })) {
                        anchor.users.push(Object.assign({},item, {targetRoomNo: anchor.roomNo}));
                        return true;
                    }
                })
            },
            closeUser(account) {
                console.log(account)
                this.anchors.some((item) => {
                    console.log(item.roomNo + '===' + account.targetRoomNo)
                    if (item.roomNo === account.targetRoomNo) {
                        item.users.some((user, index) => {
                            if (user.mobile === account.mobile) {
                                item.users.splice(index, 1);
                                return true
                            }
                        })
                    }
                });
            },
        }
    }
</script>

<style scoped>
    .add-div {
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .add {
        display: flex;
        justify-items: center;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .add i {
        font-size: 16px;
        color: #ff6600
    }

    .add span {
        font-size: 14px;
        color: #ff6600
    }
</style>
