<template>
    <div style="position: relative">

        <el-card>
            <template v-if="!user">
                <div slot="header">
                    请登陆主播账号{{ account.roomNo }}
                    <i class="el-icon-close" style="float: right;cursor: pointer"
                       @click="$emit('close-anchor',account)"/>
                </div>
                <el-form>
                    <el-form-item label="手机号码">
                        <el-input v-model="loginForm.mobile"></el-input>
                    </el-form-item>
                    <el-form-item label="密码">
                        <el-input v-model="loginForm.password"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="login">登录</el-button>
                    </el-form-item>
                </el-form>
            </template>
            <template v-else>
                <div slot="header" class="clearfix">
                    <div style="float: left">
                        主播：{{ user.nickname }}[{{ user.id }}]
                        <span v-if="room">
                            &nbsp;&nbsp;&nbsp;直播间：{{ room.name }}
                       </span>

                    </div>
                    <i class="el-icon-close" style="float: right;cursor: pointer"
                       @click="$emit('close-anchor',account)"/>
                </div>
                <div>
                    <el-alert style="margin-bottom: 20px;height: 50px" type="success"
                              :closable="!isInteraction && !isPunish"
                              @close="interactionData = null" v-if="interactionData">
                        <div class="pk">
                            <!--                            <span>{{ pkData.self_user.nickname }}({{ pkData.self_contribute_total }})</span>-->
                            <!--                            <img src="../assets/PKvs.png" style="height: 30px; margin-left: 10px;margin-right: 10px"/>-->
                            <!--                            <span>{{ pkData.target_user.nickname }}({{ pkData.target_contribute_total }})</span>-->
                        </div>

                        <span class="time-left" v-if="isInteraction && isPunish"
                              style="color: red">惩罚时间({{ punishTimeLeft }})秒</span>
                        <span class="time-left" v-if="isInteraction && !isPunish">正在{{ interactionData.type_name }}({{
                            interactionTimeLeft
                            }})秒</span>

                        <el-button size="mini" type="dange" @click="quitInteraction">退出{{
                            interactionData.type_name
                            }}
                        </el-button>
                        <el-button size="mini" type="success" @click="requestJoin">邀请用户加入</el-button>
                        <span style="margin-left: 30px">贡献值：{{ interactionContribution }}</span>
                        <span style="margin-left: 30px">结果：{{ interactionResult }}</span>
                    </el-alert>
                    <template v-else>
                        <div style="margin-bottom: 20px;height: 50px" v-if="interactionRequest">
                            <el-alert type="success" class="clearfix" :closable="false" title="PK邀请">
                                {{ interactionRequest.inviter.nickname }}邀请你{{ interactionRequest.type_name }}
                                <div style="position: absolute;top: 12px;right: 20px;">
                                    <el-button size="small" type="primary" @click="agreeInteraction">
                                        同意({{ pendingTimeout }})
                                    </el-button>
                                    <el-button size="small" type="danger" @click="rejectInteraction">
                                        拒绝
                                    </el-button>
                                </div>
                            </el-alert>
                        </div>
                        <div style="margin-bottom: 20px;height: 50px" v-else>
                            <el-button size="small" type="warning" @click="sendMessage">发送消息</el-button>
                            <el-dropdown @command="interaction" style="margin-left: 20px">
                                <el-button type="primary" size="small">
                                    互动<i class="el-icon-arrow-down el-icon--right"></i>
                                </el-button>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item command="0">双人PK</el-dropdown-item>
                                    <el-dropdown-item command="1">互动</el-dropdown-item>
                                    <el-dropdown-item command="2">PK多人</el-dropdown-item>
                                    <el-dropdown-item command="3">多人互动</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                            <el-dropdown style="float: right" @command="command">
                                <el-button type="primary" size="small">
                                    管理<i class="el-icon-arrow-down el-icon--right"></i>
                                </el-button>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item command="add-manager">设置房管</el-dropdown-item>
                                    <el-dropdown-item command="remove-manager">取消房管</el-dropdown-item>
                                    <el-dropdown-item command="add-quiet">禁言</el-dropdown-item>
                                    <el-dropdown-item command="remove-quiet">取消禁言</el-dropdown-item>
                                    <el-dropdown-item command="add-lock">封禁</el-dropdown-item>
                                    <el-dropdown-item command="remove-lock">取消封禁</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                    </template>

                    <el-row>
                        <el-col :span="24">
                            <el-card>
                                <div slot="header" class="clearfix">

                                    <template v-if="connected">
                                        <el-tag type="success" style="margin-right: 20px">已连接</el-tag>

                                        <el-tag v-if="isJoined" type="success">直播间{{ roomNo }}</el-tag>

                                        <el-button size="small" v-if="!isJoined && room" type="danger"
                                                   @click="joinRoom">点击加入房间
                                        </el-button>
                                    </template>
                                    <template v-else>
                                        <el-tag type="danger">未连接</el-tag>

                                    </template>

                                    <el-button style="float: right; padding: 3px 0" type="text" @click="messages = []">
                                        清空({{ messages.length }})
                                    </el-button>
                                </div>
                                <div style="height: 400px; overflow-y: scroll">
                                    <el-card v-for="(item,index) in messages" :key="index" style="margin-bottom: 20px">
                                        <div slot="header" class="clearfix">
                                            <span>
                                                {{ item.event }}
                                            </span>
                                            <span style="float: right;color: #cccccc">
                                                {{ item.time }}
                                            </span>
                                        </div>

                                        <vue-json-pretty :deep="0" :data="item.data"></vue-json-pretty>

                                    </el-card>
                                </div>
                            </el-card>
                        </el-col>
                    </el-row>
                </div>
            </template>
        </el-card>
        <interaction ref="interaction" @callback="handleInteraction"/>
    </div>
</template>

<script>
import mixin from "@/mixin";
import 'vue-json-pretty/lib/styles.css'
import VueJsonPretty from "vue-json-pretty";
import Interaction from "@/components/interaction.vue";

export default {
    name: "anchor",
    mixins: [mixin],
    props: {
        account: {
            type: Object
        }
    },
    components: {
        VueJsonPretty, Interaction
    },
    data() {
        return {
            timer: null,
            interactionRequest: null,
            interactionData: null,
            isInteraction: false,
            interactionTimeLeft: null,
            pkRequest: null,
            pkData: null,
            // isPk: false,
            isPunish: false,
            pendingTimeout: null,
            pkTimeLeft: null,
            punishTimeLeft: null,
            num: 0
        }
    },
    computed: {
        interactionContribution() {
            if (!this.interactionData) {
                return 0;
            }
            const member = this.interactionData.members.find((item) => {
                return item.user_id === this.room.id
            })
            if (member) {
                return member.contribution
            }
            return 0;
        },
        interactionResult() {
            if (!this.interactionData) {
                return '未知';
            }
            if (this.isPunish) {
                if (!this.interactionData.win_user_id) {
                    return '平局';
                }
                return this.interactionData.win_user_id === this.room.id ? '赢' : '输'
            }
            return '进行中...';
        }
    },
    created() {
        this.loginForm.mobile = this.account.mobile;
        this.loginForm.password = this.account.password;
    },
    mounted() {
        this.login()
    },
    methods: {
        getRoom() {
            this.request.get('my-room/info').then(res => {
                this.room = res.data
                this.roomNo = this.room.room_no
                this.initSocket()
            }).catch(e => {

            })
        },
        requestPK() {
            this.$prompt('输入直播间号(用户ID)', '操作', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                closeOnClickModal: false,
            }).then(({value}) => {
                this.request.post('room-pk/request', {user_id: value});
            }).catch(() => {

            });

        },
        rejectPK() {
            this.request.post('room-pk/reject', {pk_id: this.pkRequest.id}).then(() => {
                this.pkRequest = null
            });
        },
        agreePK() {
            this.request.post('room-pk/agree', {pk_id: this.pkRequest.id}).then(() => {
                this.pkRequest = null
            });
        },
        rejectInteraction() {
            this.request.post('interaction/reject', {
                id: this.interactionRequest.id,
                interaction_id: this.interactionRequest.interaction_id
            }).then(() => {
                this.interactionRequest = null
            });
        },
        agreeInteraction() {
            this.request.post('interaction/agree', {
                id: this.interactionRequest.id,
                interaction_id: this.interactionRequest.interaction_id
            }).then(() => {
                this.interactionRequest = null
            });
        },


        init() {
            this.sockets.subscribe('interactionRequest', (data) => {
                this.interactionRequest = data
                console.log(this.interactionRequest)
                this.interactionData = null
                this.pendingTimeout = parseInt(this.interactionRequest.timeout);
                let timer = setInterval(() => {
                    if (this.pendingTimeout <= 1) {
                        clearInterval(timer);
                        this.interactionRequest = null
                    }
                    this.pendingTimeout--;
                }, 1000);
            })
            //
            //     this.sockets.subscribe('pkRequest', (data) => {
            //         this.pkRequest = data
            //         this.pkData = null
            //         this.pendingTimeout = parseInt(this.pkRequest.timeout);
            //         let timer = setInterval(() => {
            //             if (this.pendingTimeout <= 1) {
            //                 clearInterval(timer);
            //                 this.pkRequest = null
            //             }
            //             this.pendingTimeout--;
            //         }, 1000);
            //     })
            //     this.sockets.subscribe('pkRequestTimeout', (data) => {
            //         this.pkRequest = null
            //     })
            //     this.sockets.subscribe('pkUpdateProgress', (data) => {
            //         this.isPk = true
            //         this.pkData = data
            //         if (this.pkTimeLeft === null) {
            //             this.pkTimeLeft = parseInt(data.pk_time_left) - 1;
            //             let timer = setInterval(() => {
            //                 if (this.pkTimeLeft <= 1) {
            //                     clearInterval(timer);
            //                 }
            //                 this.pkTimeLeft--;
            //             }, 1000);
            //         }
            //     })
            //     this.sockets.subscribe('pkStart', (data) => {
            //         this.isPk = true
            //         this.pkData = data
            //         this.pkTimeLeft = parseInt(data.pk_duration) - 1;
            //         console.log(this.pkTimeLeft)
            //         let timer = setInterval(() => {
            //             if (this.pkTimeLeft <= 1) {
            //                 clearInterval(timer);
            //             }
            //             this.pkTimeLeft--;
            //         }, 1000);
            //     })
            //     this.sockets.subscribe('pkEnd', (data) => {
            //         this.isPk = true
            //         this.isPunish = true
            //         this.pkData = data
            //         this.punishTimeLeft = parseInt(data.punish_time_left)
            //         let timer = setInterval(() => {
            //             if (this.punishTimeLeft <= 1) {
            //                 clearInterval(timer);
            //             }
            //             this.punishTimeLeft--;
            //         }, 1000);
            //     })
            //     this.sockets.subscribe('pkClose', (data) => {
            //         this.isPk = false
            //         this.isPunish = false
            //         this.pkData = data
            //     })
            this.sockets.subscribe('interactionAgreed', (data) => {

            })


            this.sockets.subscribe('interactionStarted', (data) => {
                this.isInteraction = true
                this.interactionData = data
                this.interactionTimeLeft = data.time_left
                clearInterval(this.timer)
                this.timer = setInterval(() => {
                    if (this.interactionTimeLeft <= 1) {
                        clearInterval(this.timer)
                    }
                    this.interactionTimeLeft--;
                }, 1000);
            })
            this.sockets.subscribe('interactionProgress', (data) => {
                this.isInteraction = true
                this.interactionData = data
                if (this.interactionTimeLeft === null) {
                    this.interactionTimeLeft = data.time_left;
                    clearInterval(this.timer)
                    this.timer = setInterval(() => {
                        if (this.interactionTimeLeft <= 1) {
                            clearInterval(this.timer)
                        }
                        this.interactionTimeLeft--;
                    }, 1000);
                }
            })

            this.sockets.subscribe('interactionEnded', (data) => {
                this.isInteraction = true
                this.isPunish = true
                this.interactionData = data
                this.punishTimeLeft = parseInt(data.punish_duration) - 1
                clearInterval(this.timer)
                this.timer = setInterval(() => {
                    if (this.punishTimeLeft <= 1) {
                        clearInterval(this.timer);
                    }
                    this.punishTimeLeft--;
                }, 1000);
            })

            this.sockets.subscribe('interactionClosed', (data) => {
                this.isInteraction = false
                this.isPunish = false
                this.interactionData = null
            })
        },
        sendMessage() {
            this.$prompt('输入内容', '操作', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                closeOnClickModal: false,
            }).then(({value}) => {
                this.socket.io.emit('sendMessage', {content: value, room_no: this.roomNo});
            }).catch(() => {

            });
        },
        test() {
            this.socket.io.emit('test', {'num': this.num++})
        },
        command(name) {
            this.$prompt('输入用户ID', '操作', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                closeOnClickModal: false,
            }).then(({value}) => {
                this.request.post(`room-manage/${name}`, {user_id: value, room_no: this.roomNo}).then(res => {

                }).catch(e => {

                })
            }).catch(() => {

            });
        },
        interaction(type) {
            this.$refs.interaction.show(type)
        },
        requestJoin() {
            this.$refs.interaction.show(this.interactionData.type)
        },
        handleInteraction(data) {
            if (this.interactionData) {
                this.request.post('interaction/request', Object.assign({}, data, {interaction_id: this.interactionData.id})).then(() => {
                    this.$refs.interaction.close()
                }).catch(e => {
                    this.$message.error(e.message)
                });
            } else {
                this.request.post('interaction/request', data).then(() => {
                    this.$refs.interaction.close()
                }).catch(e => {
                    this.$message.error(e.message)
                });
            }
        },
        quitInteraction() {
            this.request.post('interaction/quit', Object.assign({
                interaction_id: this.interactionData.id
            })).then(() => {

            }).catch(e => {
                this.$message.error(e.message)
            });
        }
    }
}
</script>

<style scoped>
.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
}

.clearfix:after {
    clear: both
}

.pk {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

}

.time-left {
    position: absolute;
    right: 20px;
    top: 20px;
}

</style>
