<template>
    <el-dialog :visible.sync="visible" :title="title" width="500px" :close-on-click-modal="false">
        <el-form inline>
            <el-form-item label="用户" label-width="100px">
                <el-input v-model="user_ids" placeholder="多个用户请逗号分割" style="width: 300px"/>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button size="small" @click="visible = false">取 消</el-button>
            <el-button size="small" type="danger" @click="random">随机用户</el-button>
            <el-button size="small" type="primary" @click="submit">发送请求</el-button>
        </div>
    </el-dialog>
</template>
<script>
export default {
    data() {
        return {
            type: 0,
            visible: false,
            user_ids: ''
        }
    },
    methods: {
        show(type) {
            this.type = parseInt(type)
            this.visible = true
        },
        close() {
            this.visible = false
        },
        submit() {
            this.$emit('callback', {type: this.type, random: 0, user_ids: this.user_ids.split(',')})
        },
        random() {
            this.$emit('callback', {type: this.type, random: 1, user_ids: []})
        }
    },
    computed: {
        title() {
            switch (this.type) {
                case 0 :
                    return 'PK';
                case 1:
                    return '互动';
                case 2:
                    return '多人PK';
                case 3:
                    return '多人互动'
                default:
                    return 'PK'
            }
        }
    }
}
</script>
